.c-big-message {
    padding: 40px 20px;
    border-radius: 3px;
    background: $highlight--lighter;
    text-align: center;
}

.c-big-message__inner {
	max-width: 650px;
	margin: 0 auto;
}

.c-big-message__icon {
	margin-bottom: 10px;
}

.c-big-message__icon .i {
	display: inline-block;
	border-radius: 50%;
	border: 2px solid $bodyColour;
	border-color: inherit;
	width: 30px;
	line-height: 26px;
	text-align: center;
	vertical-align: middle;
}

.c-big-message__icon .i:after {
	padding: 0;
}

.c-big-message__icon--big {
	margin-bottom: 15px;

	& .i {
		width: 60px;
		line-height: 56px;
		font-size: 30px;
	}
}

.c-big-message__header {
	margin-bottom: 15px;
}

.c-big-message--success {
	background: #F1F6EE;

	& .c-big-message__icon {
		color: $colourSuccess;
	}
}
.c-big-message--warning {
	background: #fff9e4;

	& .c-big-message__icon {
		color: $colourWarning;
	}
}