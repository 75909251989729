.l-container {
	width: 100%;
    min-width: 320px;
    max-width: 1250px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
}
.l-container--no-shadow {
    box-shadow: none;
}
.l-container__inner {
	width: 95%;
    margin: 0 auto;
}

.l-main {
	min-height: 400px;
	@include mq($until: palm) {
        min-height: 0;
    }
}