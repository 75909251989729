.c-card {
	padding: 15px;
	border: 1px solid $highlight--darker;
	border-radius: 3px;
}
.c-card--highlight {
	background: $highlight;
}

.c-card--highlight-lighter {
	background: $highlight--lighter;
}

.c-card--no-border {
	border: 0;
}