
.h-text-align-left {
	text-align: left !important;
}
.h-text-align-right {
	text-align: right !important;
}
.h-text-align-center {
	text-align: center !important;
}

@mixin device-type($namespace:"") {
    .h-text-align-left--#{$namespace}        { text-align: left !important; }
    .h-text-align-right--#{$namespace}       { text-align: right !important; }
    .h-text-align-center--#{$namespace}      { text-align: center !important; }
}
@each $name in $breakpoint-has-widths {
    @include grid-media-query($name) {
       @include device-type('#{$name}');
    }
}

.h-text-colour-success {
	color: $colourSuccess !important;
}
.h-text-colour-warning {
	color: $colourWarning !important;
}
.h-text-colour-error {
	color: $colourError !important;
}


.h-word-no-wrap {
	white-space: nowrap !important;
}
.h-word-break-all {
	word-break: break-all !important;
}
.h-word-pre-wrap {
	white-space: pre-wrap !important;
}

.h-format-text ul {
	list-style-type: disc;
	list-style-position: outside;
	margin-left: 20px;
}
.h-format-text ol {
	list-style-type: decimal;
	list-style-position: outside;
	margin-left: 20px;
}


.h-link {
    margin: 0 !important;
    padding: 0 !important;
    background: none !important;
    border: 0 !important;
    display: inline !important;
    cursor: pointer !important;
    font: inherit !important;
}