$lines: 3;

.c-crop-text {
	max-height: ($lines + 1) * $baseLineHeight * 1em;
	overflow: hidden;
	position: relative;

	&:after {
		display: block;
		content: "";
		background: linear-gradient(rgba(#fff, 0) 50%, #fff 100%);
		width: 100%;
		height: ($lines + 1) * $baseLineHeight * 1em;
		position: absolute;
		top: 0;
	}

	&--is-expanded {
		max-height: 100%;

		&:after {
			display: none;
		}
	}
}

.c-crop-text-button {
	margin-top: 5px;
	text-align: right;
}
