/*------------------------------------*\
    $CSSWIZARDRY-GRIDS -- MODIFIED
\*------------------------------------*/
/**
 * CONTENTS
 * MODIFIED NOTES.......How the grids have been modified
 * INTRODUCTION.........How the grid system works.
 * VARIABLES............Your settings.
 * MIXINS...............Library mixins.
 * GRID SETUP...........Build the grid structure.
 * WIDTHS...............Build our responsive widths around our breakpoints.
 */





/*------------------------------------*\
    $MODIFIED NOTES
\*------------------------------------*/
/**
 * Changed grid to floats
 * Added option for flexbox grids
 * Removed push and pull classes
 * Removed rev class
 * Removed grid align class, as they don't work with floats
 * Removed the following settings: 
 *     responsive - assumed its always responsive
 *     mobile first - assumed its always mobile first
 *     use-silent-classes - we want regular css classes
 * Removed mark up fix, as we'll be using floats
 * Removed class type setting
 * Removed vertical align classes, as they don't work on floats
 * Removed full, narrow and wide classes, as don't use them
 */





/*------------------------------------*\
    $INTRODUCTION
\*------------------------------------*/
/**
 * csswizardry grids provides you with widths to suit a number of breakpoints
 * designed around devices of a size you specify. Out of the box, csswizardry
 * grids caters to the following types of device:
 *
 * palm     --  palm-based devices, like phones and small tablets
 * lap      --  lap-based devices, like iPads or laptops
 * portable --  all of the above
 * desk     --  stationary devices, like desktop computers
 *
 * These namespaces are then used in the library to give you the ability to
 * manipulate your layouts based around them, for example:
 *
   <div class="grid__item  one-whole  lap--one-half  desk--one-third">
 *
 * This would give you a grid item which is 100% width unless it is on a lap
 * device, at which point it become 50% wide, or it is on a desktop device, at
 * which point it becomes 33.333% width.
 *
 * All classes in csswizardry grids follow this patten, so you should fairly
 * quickly be able to piece together any combinations you can imagine, for
 * example:
 *
   `class="grid__item  one-whole  lap--one-half  desk--one-third`
 *
   `class="grid__item  one-quarter  palm--one-half`
 *
   `class="grid__item  palm--one-third  desk--five-twelfths`
 */





/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/
/**
 * Set the spacing between your grid items.
 */
$spacing: 20px !default;


/**
 * Define your breakpoints. The first value is the prefix that shall be used for
 * your classes (e.g. `.palm--one-half`), the second value is the media query
 * that the breakpoint fires at.
 */
$breakpoints: (
    'palm' '(max-width: 550px)',
    'portable' '(min-width: 550px) and (max-width: 750px)',
    'lap' '(min-width: 550px) and (max-width: 1024px)',
    'desk' '(min-width: 1025px)'
) !default;


/**
 * Define which namespaced breakpoints you would like to generate for each of
 * widths, push and pull. This is handy if you only need pull on, say, desk, or
 * you only need a new width breakpoint at mobile sizes. It allows you to only
 * compile as much CSS as you need. All are turned on by default, but you can
 * add and remove breakpoints at will.
 */
$breakpoint-has-widths: ('palm', 'portable', 'lap', 'desk') !default;





/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
/**
 * These mixins are for the library to use only, you should not need to modify
 * them at all.
 *
 * Enclose a block of code with a media query as named in `$breakpoints`.
 */
@mixin grid-media-query($media-query) {
    $breakpoint-found: false;

    @each $breakpoint in $breakpoints {
        $name: nth($breakpoint, 1);
        $declaration: nth($breakpoint, 2);

        @if $media-query == $name and $declaration {
            $breakpoint-found: true;

            @media only screen and #{$declaration} {
                @content;
            }
        }
    }

    @if not $breakpoint-found {
        @warn "Breakpoint '#{$media-query}' does not exist";
    }
}





/*------------------------------------*\
    $GRID SETUP
\*------------------------------------*/
/**
 * 1. Allow the grid system to be used on lists.
 * 2. Remove any margins and paddings that might affect the grid system.
 * 3. Apply a negative `margin-left` to negate the columns' gutters.
 */
.l-grid {
    list-style: none;                /* [1] */
    margin: 0;                       /* [2] */
    padding: 0;                      /* [2] */
    margin-left: -$spacing;          /* [3] */
}

.l-grid:after {
    height: 0;
    display: block;
    content: "";
    visibility: hidden;
    clear: both;
}

/**
 * Change the grid to remove padding
 */
.l-grid--full {
    margin-left: 0;
}

.l-grid--full > .l-grid__item {
    padding-left: 0;
}

/**
 * Change the grid to use flexbox
 *
 * 1. set to flexbox display
 * 2. center items horizontally
 * 3. center items vertically
 * 4. wrap items when needed
 */
.l-grid--flex {
    display: flex;               /* [1] */
    display: -ms-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;

    justify-content: center;     /* [2] */
    -ms-flex-pack: center;
    -ms-justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;

    align-items: center;         /* [3] */
    -ms-flex-align: center;
    -ms-align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;

    flex-wrap: wrap;             /* [4] */
    -ms-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

/**
 * Grid modifiers for flex box
 */
.l-grid--flex.l-grid--top {
    align-items: flex-start;
    -ms-flex-align: flex-start;
    -ms-align-items: flex-start;
    -moz-align-items: flex-start;
    -webkit-align-items: flex-start;
}

.l-grid--flex.l-grid--bottom {
    align-items: flex-end;
    -ms-flex-align: flex-end;
    -ms-align-items: flex-end;
    -moz-align-items: flex-end;
    -webkit-align-items: flex-end;
}

.l-grid--flex.l-grid--stretch {
    align-items: stretch;
    -ms-flex-align: stretch;
    -ms-align-items: stretch;
    -moz-align-items: stretch;
    -webkit-align-items: stretch;
}
.l-grid--flex.l-grid--left {
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -ms-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
.l-grid--flex.l-grid--right {
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -ms-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.l-grid--flex.l-grid--stretch > .l-grid__item {
    display: flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;

    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.l-grid--flex.l-grid--stretch .l-grid__item > * {
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; 
}


/**
 * 1. Cause columns to stack side-by-side.
 * 2. Space columns apart.
 * 3. Align columns to the tops of each other.
 * 4. Full-width unless told to behave otherwise.
 */
.l-grid__item {
    min-height: 1px;
    float: left;              /* [1] */
    padding-left: $spacing;   /* [2] */
    vertical-align: top;      /* [3] */
    width: 100%;              /* [4] */
}

/**
 * Set the defaults of each flex item
 *
 * 1. Set default order for flex item (higher than the max number of columns)
 * 2. Remove float
 * 3. Stop items shrinking smaller than their width (flex-basis)
 */
.l-grid--flex > .l-grid__item {
    order: 13;          /* [] */
    -webkit-order: 13;
    -moz-order: 13;
    -ms-flex-order: 13;
    float: none;       /* [2] */

    flex-shrink: 0;    /* [3] */
    -ms-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -webkit-flex-shrink: 0;
}





/*------------------------------------*\
    $WIDTHS
\*------------------------------------*/
/**
 * Create our width classes, prefixed by the specified namespace.
 */
@mixin device-type($namespace:"") {
    $prefix: "." + $namespace;

    /**
     * Whole
     */
    #{$prefix}one-whole         { width:100%; }


    /**
     * Halves
     */
    #{$prefix}one-half          { width:50%; }


    /**
     * Thirds
     */
    #{$prefix}one-third         { width:33.333%; }
    #{$prefix}two-thirds        { width:66.666%; }


    /**
     * Quarters
     */
    #{$prefix}one-quarter       { width:25%; }
    #{$prefix}two-quarters      { @extend #{$prefix}one-half; }
    #{$prefix}three-quarters    { width:75%; }


    /**
     * Fifths
     */
    #{$prefix}one-fifth         { width:20%; }
    #{$prefix}two-fifths        { width:40%; }
    #{$prefix}three-fifths      { width:60%; }
    #{$prefix}four-fifths       { width:80%; }


    /**
     * Sixths
     */
    #{$prefix}one-sixth         { width:16.666%; }
    #{$prefix}two-sixths        { @extend #{$prefix}one-third; }
    #{$prefix}three-sixths      { @extend #{$prefix}one-half; }
    #{$prefix}four-sixths       { @extend #{$prefix}two-thirds; }
    #{$prefix}five-sixths       { width:83.333%; }


    /**
     * Eighths
     */
    #{$prefix}one-eighth        { width:12.5%; }
    #{$prefix}two-eighths       { @extend #{$prefix}one-quarter; }
    #{$prefix}three-eighths     { width:37.5%; }
    #{$prefix}four-eighths      { @extend #{$prefix}one-half; }
    #{$prefix}five-eighths      { width:62.5%; }
    #{$prefix}six-eighths       { @extend #{$prefix}three-quarters; }
    #{$prefix}seven-eighths     { width:87.5%; }


    /**
     * Tenths
     */
    #{$prefix}one-tenth         { width:10%; }
    #{$prefix}two-tenths        { @extend #{$prefix}one-fifth; }
    #{$prefix}three-tenths      { width:30%; }
    #{$prefix}four-tenths       { @extend #{$prefix}two-fifths; }
    #{$prefix}five-tenths       { @extend #{$prefix}one-half; }
    #{$prefix}six-tenths        { @extend #{$prefix}three-fifths; }
    #{$prefix}seven-tenths      { width:70%; }
    #{$prefix}eight-tenths      { @extend #{$prefix}four-fifths; }
    #{$prefix}nine-tenths       { width:90%; }


    /**
     * Twelfths
     */
    #{$prefix}one-twelfth       { width:8.333%; }
    #{$prefix}two-twelfths      { @extend #{$prefix}one-sixth; }
    #{$prefix}three-twelfths    { @extend #{$prefix}one-quarter; }
    #{$prefix}four-twelfths     { @extend #{$prefix}one-third; }
    #{$prefix}five-twelfths     { width:41.666% }
    #{$prefix}six-twelfths      { @extend #{$prefix}one-half; }
    #{$prefix}seven-twelfths    { width:58.333%; }
    #{$prefix}eight-twelfths    { @extend #{$prefix}two-thirds; }
    #{$prefix}nine-twelfths     { @extend #{$prefix}three-quarters; }
    #{$prefix}ten-twelfths      { @extend #{$prefix}five-sixths; }
    #{$prefix}eleven-twelfths   { width:91.666%; }


    /**
     * Grid modifiers for flex box ordering
     */
    .l-grid--flex #{$prefix}order-first {
        order: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        -moz-order: 1;
        -ms-order: 1;
    }

    .l-grid--flex #{$prefix}order-second {
        order: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        -moz-order: 2;
        -ms-order: 2;
    }

    .l-grid--flex #{$prefix}order-third {
        order: 3;
        -ms-flex-order: 3;
        -webkit-order: 3;
        -moz-order: 3;
        -ms-order: 3;
    }

    .l-grid--flex #{$prefix}order-fourth {
        order: 4;
        -ms-flex-order: 4;
        -webkit-order: 4;
        -moz-order: 4;
        -ms-order: 4;
    }

    .l-grid--flex #{$prefix}order-fifth {
        order: 5;
        -ms-flex-order: 5;
        -webkit-order: 5;
        -moz-order: 5;
        -ms-order: 5;
    }

    .l-grid--flex #{$prefix}order-sixth {
        order: 6;
        -ms-flex-order: 6;
        -webkit-order: 6;
        -moz-order: 6;
        -ms-order: 6;
    }

    .l-grid--flex #{$prefix}order-seventh {
        order: 7;
        -ms-flex-order: 7;
        -webkit-order: 7;
        -moz-order: 7;
        -ms-order: 7;
    }

    .l-grid--flex #{$prefix}order-eighth {
        order: 8;
        -ms-flex-order: 8;
        -webkit-order: 8;
        -moz-order: 8;
        -ms-order: 8;
    }

    .l-grid--flex #{$prefix}order-ninth {
        order: 9;
        -ms-flex-order: 9;
        -webkit-order: 9;
        -moz-order: 9;
        -ms-order: 9;
    }

    .l-grid--flex #{$prefix}order-tenth {
        order: 10;
        -ms-flex-order: 10;
        -webkit-order: 10;
        -moz-order: 10;
        -ms-order: 10;
    }

    .l-grid--flex #{$prefix}order-eleventh {
        order: 11;
        -ms-flex-order: 11;
        -webkit-order: 11;
        -moz-order: 11;
        -ms-order: 11;
    }

    .l-grid--flex #{$prefix}order-twelth {
        order: 12;
        -ms-flex-order: 12;
        -webkit-order: 12;
        -moz-order: 12;
        -ms-order: 12;
    }
}


/**
 * Our regular, non-responsive width classes.
 */
@include device-type;


/**
 * Our responsive classes.
 */
@each $name in $breakpoint-has-widths {
    @include grid-media-query($name) {
       @include device-type('#{$name}--');
    }
}