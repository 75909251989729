.c-character-filter {
	border: 1px solid $highlight--darker;
	padding: 5px;
}

.c-character-filter__items {
	text-align: center;
	margin-bottom: 0;
}

.c-character-filter__item {
	display: inline-block;
	color: #ccc;
}

.c-character-filter__link {
	display: inline-block;
	min-width: 35px;
	padding: 3px 8px;
	text-align: center;
}

.c-character-filter__link--is-active {
	background: $highlight;
	color: $bodyColour;
}