.c-back-to-top {
	background: $highlight;
	display: none;
	margin-bottom: 30px; 
	@include mq($until: lap) { 
		display: block;
	}
}
.c-back-to-top__text {
	margin: 0
}
.c-back-to-top__link {
	text-align: right;
	padding: 10px;
	display: block;
}