html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit
}

* {
	-o-box-sizing: border-box;
	box-sizing: border-box;
	word-wrap: break-word
}

html {
	-webkit-text-size-adjust: none
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	display: block
}

img,
object,
embed {
	max-width: 100%;
}

img {
	-ms-interpolation-mode: bicubic;
	box-sizing: content-box;
}

a {
	text-decoration: none;
	color: $linkColour
}

ol,
ul {
	list-style-type: none;
	list-style-position: outside
}

/*
* Change style on body and container when in iframe
*/
html.is-iframe,
.is-iframe body,
.is-iframe .l-container__inner,
.is-iframe .l-main,
.is-iframe .s-iframe-modal-form {
	height: 100%;
	margin: 0 auto;
}

.is-iframe {
	min-width: 0;
}
.is-iframe body {
	background: #fff;
}
.is-iframe .l-container {
	box-shadow: none;
}
.is-frame .l-main {
	min-height: 0;
}