.h-float-left { 
	float: left !important;
}
.h-float-right { 
	float: right !important;
}
.h-float-none {
	float: none !important;
}

.h-clear-left {
	clear: left !important;
}
.h-clear-right {
	clear: right !important;
}
.h-clear-both { 
	clear: both !important;
}

.h-cf {
	@include clearfix();
}

.h-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.h-display-block,
.js .h-js-display-block {
	display: block !important;
}
.h-display-inline,
.js .h-js-display-inline {
	display: inline !important;
}
.h-display-inline-block,
.js .h-js-display-inline-block {
	display: inline-block !important;
}
.h-display-table,
.js .h-js-display-table {
	display: table !important;
}
.h-display-table-cell,
.js .h-js-display-table-cell {
	display: table-cell !important;
}
.h-display-none,
.js .h-js-display-none {
	display: none !important;
}

.h-mortar {
	margin-bottom: $defaultVerticalMargin !important;
}
.h-mortar--larger {
	margin-bottom: $defaultVerticalMargin * 2 !important;
}
.h-mortar--smaller {
	margin-bottom: $defaultVerticalMargin / 2 !important;
}
.h-margin-none {
	margin: 0 !important;
}
.h-padding-none {
	padding: 0 !important;
}

.h-center-block {
	margin-left: auto !important;
	margin-right: auto !important;
}

.h-display-flex {
	display: flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
}
.h-flex-1 {
	-webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; 
}

.h-full-height {
	height: 100vh;
}

/* Borders */
.h-single-border-left {
	border: 0;
	padding: 0;
	border-left: 1px solid $highlight--darker;
	padding-left: 15px;
}
.h-single-border-right {
	border: 0;
	padding: 0;
	border-right: 1px solid $highlight--darker;
	padding-right: 15px;
}
.h-single-border-top {
	border: 0;
	padding: 0;
	border-top: 1px solid $highlight--darker;
	padding-top: 15px;
}
.h-single-border-bottom {
	border: 0;
	padding: 0;
	border-bottom: 1px solid $highlight--darker;
	padding-bottom: 15px;
}

@mixin device-type($namespace:"") {

	.h-visually-hidden--#{$namespace} {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	.h-display-block--#{$namespace},
	.js .h-js-display-block--#{$namespace} {
		display: block !important;
	}
	.h-display-inline--#{$namespace},
	.js .h-js-display-inline--#{$namespace} {
		display: inline !important;
	}
	.h-display-inline-block--#{$namespace},
	.js .h-js-display-inline-block--#{$namespace} {
		display: inline-block !important;
	}
	.h-display-none--#{$namespace},
	.js .h-js-display-none--#{$namespace} {
		display: none !important;
	}
	.h-mortar--#{$namespace} {
		margin-bottom: $defaultVerticalMargin !important;
	}
	.h-margin-none--#{$namespace} {
		margin: 0 !important;
	}
	.h-padding-none--#{$namespace} {
		padding: 0 !important;
	}
	.h-float-left--#{$namespace} { 
		float: left !important;
	}
	.h-float-right--#{$namespace} { 
		float: right !important;
	}
	.h-float-none--#{$namespace} {
		float: none !important;
	}
	.h-single-border-left--#{$namespace} {
		border: 0;
		padding: 0;
		border-left: 1px solid $highlight--darker;
		padding-left: 15px;
	}
	.h-single-border-right--#{$namespace} {
		border: 0;
		padding: 0;
		border-right: 1px solid $highlight--darker;
		padding-right: 15px;
	}
	.h-single-border-top--#{$namespace} {
		border: 0;
		padding: 0;
		border-top: 1px solid $highlight--darker;
		padding-top: 15px;
	}
	.h-single-border-bottom--#{$namespace} {
		border: 0;
		padding: 0;
		border-bottom: 1px solid $highlight--darker;
		padding-bottom: 15px;
	}
}
@each $name in $breakpoint-has-widths {
    @include grid-media-query($name) {
       @include device-type('#{$name}');
    }
}
