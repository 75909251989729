.js .c-popover-content,
.c-popover-content--nojs-hide {
	display: none;
}

.c-popover-content {
	width: 100% !important;
}

.c-popover-content ul {
	margin-bottom: 0;
}

.webui-popover {
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
	z-index: 200; /* lowering z-index so it doesn't go over the lightbox */
}

.webui-popover .webui-popover-content {
	padding: 15px;
}

/* Making the "yes / no" buttons a bit wider on the popup confirm for better accessibility on touch devices */
.c-button--popover-confirm {
	padding: 8px 20px;

	&:last-child {
		margin-left: 10px;
	}
}
