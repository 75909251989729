/*
* Magnific popup style overrides
*/
.mfp-content {
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 50px -10px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	max-width: 800px;
	max-height: 600px;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
}
.mfp-iframe-scaler iframe {
	border-radius: 3px;
	background: #fff;
}
.mfp-iframe-submit .mfp-content {
	width: 80%;
	height: 60%;
	-webkit-overflow-scrolling: touch;
}
.mfp-iframe-submit iframe {
	border-radius: 4px;
	width: 100%;
	height: 100%;
}

.mfp-close-btn-in .mfp-close {
    right: 0px;
    top: 0px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    color: $bodyColour;
}

/*
* Add padding when content is in lightbox
*/
.c-lightbox {
	padding: 30px;
}

/*
* Lightbox iframe styles
*/
.c-lightbox--iframe,
.c-lightbox--crop-image {
	position: relative;
	background-color: #fff;
	padding: 30px;
	padding-bottom: 110px;
	height: 100%;
	overflow-y: auto;
}

.c-lightbox__footer {
	z-index: 100;
	border-radius: 0 0 3px 3px;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: right;
	padding: 20px;
	position: fixed;
	background-color: #F8F8F8;
	box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 0px 5px 2px rgba(0, 0, 0, 0.05);
	@include clearfix();
}

/* Lightbox for crop image tool */
/* It's the same styling as c-lightbox--iframe but the footer needs a change. 
Since it's not contained in an iframe, we can't use position: fixed; (doesn't work on IE)
*/

.c-lightbox--crop-image .c-lightbox__footer {
	position: absolute;
}
