.c-message {
    padding: 10px;
    border-radius: 3px;
}
.c-message--success {
    background: $highlightSuccess;
    color: $colourSuccess;
}
.c-message--warning {
    background: $highlightWarning;
    color: $colourWarning;
}
.c-message--error {
    background: $highlightError;
    color: $colourError;
}


.c-message--notify {
    display: inline-block;
    padding: 20px 35px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 5px 20px rgba(0,0,0,0.25);

    animation: confirm-fadeIn 0.25s;
    animation-fill-mode: forwards;

    &.c-message--success {
        border: 1px solid $colourSuccess;
    }

    &.c-message--warning {
        border: 1px solid $colourWarning;
    }

    &.c-message--error {
        border: 1px solid $colourError;
    }
}

@keyframes confirm-fadeIn {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(-75%);
    }

    to {
        opacity: 1;
        transform: translateX(-50%) translateY(-115%);
    }
}