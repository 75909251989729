.s-upload {
	background: #e6e6e6;
	padding: 15px 10px;
	border-radius: 3px;
	@include clearfix();
}

.s-upload__file-input {
	float: left;
}

.s-upload__file-input,
.s-upload__filechooser-input {
	width: 50%;
}

.s-upload__file-input .c-field {
	margin-bottom: 0;
}
.s-upload__file-input .c-field__input--file {
	background: #f6f6f6;
	border-color: darken($highlight--darker,10);
	padding: 10px 8px;
}

.s-upload__filechooser-input {
	float: right;
}

.s-upload__filechooser-separator {
	float: left;
	padding: 9px 15px 0;
	text-transform: uppercase;
	font-weight: bold;
}

.s-upload__filechooser-buttons {
	display: flex;
	justify-content: space-between;
}

.s-upload__filechooser-buttons .c-button {
	margin-right: 6px;
	padding: 6px 10px;

	flex: 1 1 33.333%;

	&:last-child {
		margin-right: 0;
	}

	&.i:after {
		font-size: 20px;
	}
}

.s-upload__filechooser-button--dropbox {
	background: #007ee5;

	&:hover,
	&:focus {
		background: darken(#007ee5,12);
	}
}
.s-upload__filechooser-button--gdrive {
	background: #4386fc;

	&:hover,
	&:focus {
		background: #2263d6;
	}
}
.s-upload__filechooser-button--skydrive {
	background: #094ab1;

	&:hover,
	&:focus {
		background: darken(#094ab1,10);
	}
}

@include mq($until: palm) {
	.s-upload__file-input,
	.s-upload__filechooser-input {
		float: none;
		width: 100%;
	}

	.s-upload__filechooser-input {
		text-align: center;
	}

	.s-upload__filechooser-separator {
		float: none;
		display: block;
		padding: 15px 0;
	}

	.s-upload__filechooser-buttons {
		display: flex;
		justify-content: space-between;
	}
	.s-upload__filechooser-buttons .c-button {
		flex: 1 1 33.333%;
	}
}