@media print {
	.s-banner,
	.s-contentinfo,
	.c-pagination,
	.c-top-pagination,
	.c-advert,
	.c-static-bar {
		display: none;
	}

	.h-print-show {
		display: block;
	}
	.h-print-hide {
		display: none;
	}
}