.c-accordion__trigger {
	display: block;
	cursor: pointer;
}
.c-accordion__content {
	display: block;
}

.js .c-accordion__content {
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.3s ease-out;
	display: none;
}

.js .c-accordion__content--is-active {
	max-height: 9999px;
	display: block;
}


/* Small devices accordion */

.c-small-devices-accordion__trigger--hide {
	display: none !important;
}

.no-js .c-small-devices-accordion__trigger a {
	color: inherit !important;
	pointer-events: none;
	cursor: default;

	&:before,
	&:after {
		display: none;
	}
}

@include mq($from:lap) {
	.c-small-devices-accordion__trigger a {
		color: inherit !important;
		pointer-events: none;
		cursor: default;

		&:before,
		&:after {
			display: none;
		}
	}
}

@include mq($until:lap) {
	.js .c-small-devices-accordion {
		padding: 0px 10px 0 15px;
		border: 1px solid $highlight--darker;
		border-radius: 3px;
		margin-bottom: $defaultVerticalMargin;
	}
	.js .c-small-devices-accordion__trigger {
		margin: 0;

		& a {
			display: block;
			padding: 15px 0;
		}
	}
	.c-small-devices-accordion__trigger a:after {
		float: right;
	}

	.js .c-small-devices-accordion__trigger--hide {
		display: block !important;
	}
	.js .c-small-devices-accordion__content {
		overflow: hidden !important;
		max-height: 0px;
		margin-bottom: 0px !important;
		display: none;
	}

	.js .c-small-devices-accordion__content--is-active {
		max-height: 9999px;
		padding-bottom: 15px;
		display: block;
	}

}

/* rotate arrow on trigger */

.js .c-accordion__trigger .i:after,
.js .c-small-devices-accordion__trigger .i:after {
	display: inline-block;
	padding: 0 5px;
	transition: 0.2s ease-in-out;
}

.js .c-accordion__trigger--is-active .i:after,
.js .c-small-devices-accordion__trigger--is-active .i:after {
	transform: rotate(-180deg);
}



