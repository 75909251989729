body {
    font: #{$baseFontSize}/#{$baseLineHeight} $fontStack;
    color: $bodyColour;
}

h1,h2,h3,h4,h5,h6,p,ul,ol,dl,blockquote,pre {
    margin: 0 0 0.8em 0
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    font-style: normal;
    font-weight: normal;
    color: $bodyColour;
}

h1,
.h1 {
    font-size: 1.8em;

    @include mq($until: palm) {
        font-size: 1.6em;
    }
}

h2,
.h2 {
    font-size: 1.5em;

    @include mq($until: palm) {
        font-size: 1.4em;
    }
}

h3,
.h3 {
    font-size: 1.2em
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: bold
}

p,
.p {
	font-size: 1em;
}

i,
em,
.em {
	font-style: italic;
}

b,
strong,
.strong {
	font-weight: bold;
}

small,
.small {
    font-size: 0.8em;
}

