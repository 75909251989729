// The variables and mixins used here are declared in _grid.scss

.l-columns {
  column-gap: $spacing;
}
.l-columns--four {
  column-count: 4;
}
.l-columns--three {
  column-count: 3;
}
.l-columns--two {
  column-count: 2;
}
.l-columns--one {
  column-count: 1;
}

@mixin device-type($namespace:"") {
    .l-columns--#{$namespace}-four        { column-count: 4; }
    .l-columns--#{$namespace}-three       { column-count: 3; }
    .l-columns--#{$namespace}-two         { column-count: 2; }
    .l-columns--#{$namespace}-one         { column-count: 1; }
}

@each $name in $breakpoint-has-widths {
    @include grid-media-query($name) {
       @include device-type('#{$name}');
    }
}