.c-button {
    margin: 0;
    padding: 8px 12px;
    background: #333;
    font: inherit;
    border: 1px solid transparent;
    border-radius: 3px;
    color: #fff;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    word-wrap: normal;
    -webkit-appearance: none;
}
.c-button:hover {
    color: #fff;
}
.c-button:disabled {
    pointer-events: none;
}

.c-button--secondary {
    background: #fff;
    border-color: $highlight--darker;
    color: #777;
    &:hover,
    &:focus {
        background: $highlight--lighter;
        color: #777;
    }
}

.c-button--smaller {
    padding: 5px 10px;
}

.c-button--larger {
    padding: 14px 16px;
    font-size: 1.1em;
}