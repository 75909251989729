.c-calendar {
	border: 1px solid $highlight--darker;
}

.c-calendar-pagination {
	padding: 8px 10px;
	border-bottom: 1px solid $highlight--darker;
	background: $highlight--lighter;
}
.c-calendar-pagination__items {
	margin-bottom: 0;
	text-align: center;
}

.c-calendar-pagination__item {
	display: inline-block;
	font-size: 1.1em;
}

.c-calendar-pagination__item--left,
.c-calendar-pagination__item--right {
	min-width: 25px;
	min-height: 23px;
}
.c-calendar-pagination__item--left {
	float: left;
	text-align: left;
}
.c-calendar-pagination__item--right {
	float: right;
	text-align: right;
}

.c-calendar-pagination__link {
	display: block;
}

.c-calendar-table-container {
	padding: 10px 0;
}

.c-calendar-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
}

.c-calendar-table th,
.c-calendar-table td {
	width: 14.285714285714286%;
	padding: 3px 0;
	font-size: 0.9em;
	text-align: center;
	color: #999;
}
.c-calendar-table td a {
	display: block;
}

.c-calendar-table th {
    font-weight: bold; 
}

.c-calendar-table__selected-day {
	background: $highlight;
}