.c-pagination {
	margin: 0;
	padding: 0;
	text-align: right;
	color: #686868;
	@include clearfix();
}
.c-pagination__item {
	display: inline-block;
}
.c-pagination__link {
	padding: 6px 11px;
	display: inline-block;
}
.c-pagination__item--is-active {
	background: $highlight;
	border-radius: 3px;
	padding: 6px 11px;
}
.c-pagination__item--is-first .c-pagination__link,
.c-pagination__link--is-previous {
	padding-left: 0;
}
.c-pagination__item--is-last .c-pagination__link,
.c-pagination__link--is-next {
	padding-right: 0;
}
.c-pagination__ellipsis {
	font-size: 0.8em;
	color: #686868;
}

.c-top-pagination {
	margin: 0 0 5px 0;
	padding: 5px 0 0;
	@include clearfix();
	text-align: left;
}

.c-top-pagination__block--is-left {
	float: left;
	padding-right: 20px;
}
.c-top-pagination__block--is-right {
	float: right;
	padding-left: 20px;
}

.c-top-pagination__item {
	display: inline-block;
}
.c-top-pagination__item--is-first {
	padding-right: 10px;
}
.c-top-pagination__item--is-last {
	padding-left: 10px;
}

.c-pagination .i--chevron-left,
.c-pagination .i--chevron-right,
.c-top-pagination .i--chevron-left,
.c-top-pagination .i--chevron-right {
	font-size: 0.8em;
}
