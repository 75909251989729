.c-social-buttons {
	margin-bottom: 20px;
}
.c-social-buttons__items {
	margin: 0;
	padding: 0;
	@include clearfix();
}
.c-social-buttons__item {
	display: inline-block;
	margin-right: 10px;
}

.c-social-buttons__item--last,
.c-social-buttons__item:last-child {
	margin-right: 0;
}

.c-social-buttons__link {
	display: block;
	padding: 5px;
}

// If the social buttons are in a popover, remove margin-bottom
.c-popover-content .c-social-buttons {
	margin-bottom: 0;
}