/* Primary nav */

.c-primary-nav {
	position: relative;
	background: $highlight;
}
.c-primary-nav .l-container__inner {
	position: relative;
}
.c-primary-nav__items-wrapper {
	overflow: hidden;
}
.c-primary-nav__items {
	margin: 0;
	padding: 0;
}
.js .c-primary-nav__items {
	display: table-cell
}
.c-primary-nav__item {
	float: left;
}
.js .c-primary-nav__item {
	float: none;
	display: table-cell;
	overflow: hidden;
    white-space: nowrap;
}
.js .c-primary-nav__item--home {
	@include mq($until: lap) {
		display: none;
    }
}
.c-primary-nav__link,
.c-primary-subnav__link {
	padding: 10px 15px;
	display: block;
}

.c-primary-nav__more-toggle {
	display: none;
	z-index: 100;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
}
.js .c-primary-nav__more-toggle {
	display: block;
}
.c-primary-nav__more-toggle-link {
	background: $highlight;
	border-left: 4px solid rgba(0, 0, 0, 0.07);
	background-clip: padding-box;
}
.c-primary-nav__more-toggle-link--is-active {
	position: relative;
	top: -1px;
	right: -4px;
	background: #fff;
	background-clip: padding-box;
	border: 1px solid $highlight--darker;
	border-right: 4px solid rgba(0, 0, 0, 0.07);
	background-clip: padding-box;
	border-bottom: 0;
}

.js .c-primary-nav__dropdown {
	position: absolute;
	top: 100%;
	max-width: 350px;
	width: 40%;
	right: -4px;
	background: #fff;
	z-index: 300;
	border: 1px solid $highlight--darker;
	border-right: 4px solid rgba(0, 0, 0, 0.07);
	border-bottom: 4px solid rgba(0, 0, 0, 0.07);
	background-clip: padding-box;
	border-top: 0;
	@include mq($until: lap) {
		max-width: 100%;
		width: 60%;
    }
	@include mq($until: palm) {
		max-width: 100%;
		width: 100%;
    }
}
.js .c-primary-nav__dropdown {
	display: none;
}
.js .c-primary-nav__dropdown--is-active {
	display: block;
}

.c-primary-nav__section-header {
	margin: 0;
	padding: 20px 20px 10px 20px;
	color: #888;
	font-size: 1em;
}

.c-user-nav__items {
	margin: 0;
	padding: 0;
}

.c-primary-nav__more-items {
	margin: 20px 0 0 0;
	padding: 20px 0;
	background: $highlight;
}
.c-primary-nav__more-item--has-border {
	border-bottom: 1px solid $highlight--darker;
}
.c-primary-nav__more-item:last-child {
	border-bottom: none;
}
.c-primary-nav__more-link {
	display: block;
	padding: 10px 20px;
}
.c-primary-nav__more-item--has-button {
	border-bottom: none;
	margin: 20px;
}
.js .c-primary-nav__more-link--sign-in,
.js .c-primary-nav__more-link--register {
	width: 100%;
}

/*
 * JS / RS link
 */

.js .c-primary-nav__switch-site {
	position: absolute;
	right: 0;
	top: -65px;
	@include mq($until: lap) {
		display: none
	}
}
.js .c-primary-nav__switch-site-link {
	width: 100%;
}
.js .c-primary-nav__more-items .c-primary-nav__switch-site {
	position: static;
	display: block;
	margin: 20px;
}

/* Primary Subnav */

.c-primary-subnav__items {
	@include clearfix();
	margin: 0;
	padding: 0;

	@include mq($until: palm) {
		display: none;
	}
}
.c-primary-subnav__item {
	float: left;
}
.c-primary-subnav__link {
	font-size: 0.9em;
}

/* Tertiary nav */

.c-tertiary-nav {
	margin-bottom: 20px;
}
.c-tertiary-nav__items {
	@include clearfix();
	margin: 0;
	padding: 0
}
.c-tertiary-nav__item {
	float: left;
	margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    @include mq($until: palm) {
    	float: none;
		margin-right: 0;
		padding-right: 0;
		border-right: 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.c-tertiary-nav__item--is-last,
.c-tertiary-nav__item:last-child {
	margin-right: 0;
	padding-right: 0;
	border: 0;
}
.c-tertiary-nav__link {
	@include mq($until: palm) {
		display: block;
		padding: 10px 0;
	}
}


/* Old Primary Nav - Transforms to hamburger menu at lap size */

/* Primary nav */

.c-primary-nav-o {
	position: relative;
	background: $highlight;
}
.c-primary-nav-o .l-container__inner {
	position: relative;
}
.c-primary-nav-o__items,
.c-secondary-nav-o__items {
	margin: 0;
	padding: 0;
	@include clearfix();
}
.c-primary-nav-o__items {
	position: relative;
}
.c-primary-nav-o__item {
	float: left;
}
.c-primary-nav-o__link,
.c-primary-subnav-o__link {
	padding: 10px 15px;
	display: block;

	.c-primary-nav-o__item--is-first &,
	.c-primary-nav-o__item:first-child & {
		padding-left: 0;
	}
}
.c-primary-nav-o__switch-site {
	position: absolute;
	right: 0;
	top: -95px;
	@include mq($until: lap) {
		display: none
	}
}
.c-primary-nav-o__switch-site-link {
	width: 100%;
}

/* Your jobs */
/* This class only appears when signed in */
.c-primary-nav-o__link--your-jobs {
	position: relative;
	padding-right: 10px;
}
.c-primary-nav-o__your-jobs-badge {
	background: darken($highlight,20);
	color: #fff;
	font-size: 0.85em;
	text-align: center;
	
	display: inline-block;
	float: right;
	width: 25px;
	height: 25px;
	line-height: 25px;
	border-radius: 50%;
	margin-left: 5px;
	margin-top: -0.15em;
}


.c-secondary-nav-o {
	position: relative;
}
.c-secondary-nav-o__items {
	position: absolute;
	top: -40px;
	right: 0;
	background: #fff;
	z-index: 1;
}
.c-secondary-nav-o__item {
	display: inline-block;
}
.c-secondary-nav-o__separator {
	color: #999;
	display: inline-block;
	padding: 0 5px;
}
.c-secondary-nav-o__item {
	border-right: 1px solid $highlight--darker;
	padding-right: 7px;
	margin-right: 7px;
	position: relative;
}

.c-secondary-nav-o__item--signin,
.c-secondary-nav-o__item--register,
.c-secondary-nav-o__item--is-last,
.c-secondary-nav-o__item:last-child {
	border-right: 0;
	padding-right: 0;
	margin-right: 0;
}

.c-user-nav-o__items {
    padding: 7px 14px;
    border: 1px solid $highlight;
    margin: 0;
}

.c-user-nav-o__item {
    display: block;
    padding: 7px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid $highlight;

    &--is-last {
        margin-bottom: 0;
        border-bottom: 0;
    }
}
.c-user-nav-o__link {
	display: block;
}

.js .c-user-nav-o__items {
    display: none;

    position: absolute;
    top: 35px;
    right: 0;
    z-index: 500;

    background-color: #fff;
    min-width: 195px;

    animation: dropdown-fadeIn 0.25s;
    animation-fill-mode: forwards;
}
.js .c-user-nav-o__items:before,
.js .c-user-nav-o__items:after {
    content: ' ';
    position: absolute;
    right: 15px;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}
.js .c-user-nav-o__items:before {
    top: -11px;
    border-bottom-color: $highlight--darker;
}
.js .c-user-nav-o__items:after {
    top: -10px;
}

.js .c-user-nav-o__items.c-togglable-nav--is-active {
    display: block;
}

.c-nav-toggles {
	display: none;
	margin: 0;
}
.c-nav-toggles__item--primary {
	float: left;
}
.c-nav-toggles__item--secondary {
	float: right;
}

@keyframes dropdown-fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@include mq($until: lap) {
	.js .c-top-bar {
		margin-bottom: 10px;
	    height: 48px;
	    background: none;
	    padding: 0;
	}
	.js .c-nav-toggles {
		display: block;
	}
	.js .c-nav-toggles__link {
		display: block;
		padding: 8px 13px;
		height: 48px;
		color: #fff;
	}
	.js .c-nav-toggles__link .i--user {
		font-size: 23px;
	}

	.js .c-togglable-nav {
		width: 100%;
		padding: 0;
		margin: 0;
		background: #fff;
		border-bottom: 5px solid $highlight--darker;
		position: absolute;
		top: 48px;
		left: 0;
		z-index: 5;
		display: none;
	}
	.js .c-togglable-nav--is-active {
		display: block;
	}

	.js .c-togglable-nav .l-container__inner,
	.js .c-togglable-nav__items {
		width: 100%;
		padding: 0;
		margin: 0;
		display: block;
		position: static;
	}

	.js .c-togglable-nav__item {
		float: none;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		border: 0;
		border-bottom: 1px solid $highlight;
		position: static;

		&--is-last {
			border-bottom: 0;
		}
	}

	.js .c-togglable-nav__item .c-togglable-nav__link,
	.js .c-togglable-nav__text {
		display: block;
		width: 100%;
		color: $bodyColour;
		padding: 14px 12px;
		text-decoration: none;

		&:hover {
			background: $highlight--lighter;
			color: $bodyColour;
		}
	}

	.js .c-togglable-nav__text,
	.js .c-togglable-nav__link--disable-link {
		color: #888;
		font-style: italic;

		&:hover {
			background: none;
			color: #888;
		}
	}

	.js .c-togglable-nav__link--disable-link {
		pointer-events: none;
		cursor: default;
		border-bottom: 1px solid $highlight;
		background: $highlight--lighter;

		&:after {
			display: none;
		}
	}

	.js .c-primary-nav-o__switch-site-link {
		background: $highlight--lighter;
		text-align: left;
	}

	.js .c-primary-nav-o__your-jobs-badge {
		float: none;
	}

	.c-secondary-nav-o__separator {
		display: none;
	}
	.js .c-user-nav-o__items {
		animation: none;
		border: none;
	}
	.js .c-user-nav-o__items:before,
	.js .c-user-nav-o__items:after {
		display: none;
	}
}
