.js .c-tinynav {
	@include mq($until: palm) {
		display: none;
	}
}

.tinynav {
	display: none;
    background: #fff;
    padding: 8px;
    border: 1px solid $highlight--darker;
    border-radius: 3px; 
    width: 100%;
    height: 40px;
    color: #333;
    font: inherit;
    margin-bottom: $defaultVerticalMargin/2;
    transition: 0.1s;  

	@include mq($until: palm) {
		display: block;
	}
}