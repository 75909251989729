.c-loading-spinner {
    position: relative;

    &:after {
        content:'';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, .2);
        border-top-color: rgba(0, 0, 0, .45);
        animation: spinner .6s linear infinite;
        -webkit-animation: spinner .6s linear infinite;
    }

}

.c-loading-spinner--white:after {
    border-color: rgba(255, 255, 255, .75);
    border-top-color: #fff;
}

.c-loading-spinner--inline {
    display: inline-block;
    vertical-align: -3px;
    margin-left: 5px;
}

.c-loading-spinner--bg, .c-loading-spinner--bg * {
    position: relative;
    z-index: 1;
}

.c-loading-spinner--bg:after {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    z-index: 0;
}

@-webkit-keyframes spinner {
    to {-webkit-transform: rotate(360deg);}
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}