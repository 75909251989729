.c-crop-image--enabled {
    position: relative;
    background-color: $highlight--lighter;
    background-repeat: no-repeat;
    background-position: center 25%;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgMTAwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNCQUJBQkE7fTwvc3R5bGU+PGc+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI4NS40LDYwLjUgODUuNCw4OS41IDEzLjYsODkuNSAxMy42LDYwLjUgMy40LDYwLjUgMy40LDEwMCA5NS42LDEwMCA5NS42LDYwLjUgIi8+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI0NC40LDE5LjkgNDQuNCw3MS41IDU0LjYsNzEuNSA1NC42LDE5LjkgNzcuNiw0Mi42IDg0LjcsMzUuNSA0OS41LDAgMTQuMywzNS41IDIxLjQsNDIuNiAiLz48L2c+PC9zdmc+');
    background-size: 40px auto;
    min-height: 10em;
    position: relative;
    border-radius: 0.3em;
    overflow: hidden;
}

.c-crop-image--enabled .c-crop-image__drop-target {
    vertical-align: middle;
    text-align: center;
    position: absolute;
    display: block;
    padding: 0;
    overflow: visible;
    cursor: pointer;
    border: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: 1px dashed rgba(0,0,0,0.25);
    outline-offset: -7px;
    transition: outline-offset .0125s, background-color .0125s;
}

.c-crop-image__label {
    display: block;
}

.c-crop-image--enabled .c-crop-image__label {
    text-align: center;
    color: #999;
    font-size: 1.1em;
    width: 100%;
    position: absolute;
    top: 50%;
    padding: 15px 20px 0;

    @include mq($until: palm) {
        font-size: 1em;
    }
}

.c-crop-image--enabled input {
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    position: absolute;
    cursor: pointer;
}
.c-crop-image--enabled .c-crop-image__cropped-image {
    display: block;
}

.c-crop-image--enabled input:focus ~ .c-crop-image__drop-target,
.c-crop-image__drop-target:focus,
.c-crop-image__drop-target:hover,
.is-dragover .c-crop-image__drop-target {
    box-shadow: inset 0 0 30px 10px rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.025);
}

.c-crop-image--enabled input:focus ~ .c-crop-image__drop-target {
    outline-color: $focusBorderColour;
    outline-style: solid;
}

.is-dragover .c-crop-image__drop-target {
    outline-offset: -12px;
}

.progress-circle {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    transform: translateY(-50%);
}

.c-lightbox--img-cropper .mfp-content {
    max-height: none;
}

/* Maintain aspect ratio of the container to 16:9 - temporary solution > needs to work with every ratio */
.c-lightbox__preview-container {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%;
    }
}
.c-lightbox__preview-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
