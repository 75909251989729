.c-separator {
	text-align: center;
	display: table;
	text-transform: uppercase;
	position: relative;
}
.c-separator:before,
.c-separator:after {
	position: relative;
	border-top: 1px solid $highlight--darker;
	content: " ";
	display: table-cell;
	top: 10px;
	width: 50%;
}
.c-separator:before {
	right: 10px;
}
.c-separator:after {
	left: 10px;
}
.c-separator__text {

}

hr {
	margin: 15px 0;
    border-top: 1px solid $highlight--darker;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
}

.c-horizontal-separator {
	display: inline-block;
	padding: 0 15px;
}

.c-horizontal-separator--palm-new-line {
	@include mq($until: palm) {
		display: block;
		padding: 15px 0;
	}
}