.c-advert,
.c-advert > * {
	text-align: center;
}
.c-advert > * {
	margin: 0 auto;
}

.c-advert--leaderboard {
	padding: 10px 0 6px;
	min-height: 111px;
	border-bottom: 1px solid #eee;
	@include mq($until: lap) {
		display: none;
	}
}
.c-advert__leaderboard-inner {
	max-width: 750px;
	margin: 0 auto;
}

.c-advert--mobile-leaderboard {
	display: none;
	@media only screen and (max-width: 750px) {
		display: block;
		padding: 10px 0 6px;
		min-height: 65px;
	}
}
.c-advert__mobile-leaderboard-inner {
	max-width: 320px;
	margin: 0 auto;
}

.c-advert--mpu img {
	width: 300px;
}

.c-advert--skyscraper img {
	max-width: 160px;
}

.c-advert--skyscraper,
.c-advert--button {
	margin-bottom: 10px;
}

.c-advert--wallpaper-left,
.c-advert--wallpaper-right {
	position: absolute;
	top: 0;
	left: -207px;
	z-index: 500;
}
.c-advert--wallpaper-right {
	left: auto;
	right: -207px;
}

.c-advert__notice {
	padding: 2px 3px;
	font-size: 12px;
}



