.c-list {
    margin: 0 0 0 20px;
    padding: 0;
}
.c-list--unordered {
    list-style-type: disc;
}
.c-list--ordered {
    list-style-type: decimal;
}

.c-list--definition {
	margin: 0;
}
.c-list__key {
	font-weight: bold
}
.c-list__value {
	margin-bottom: 10px;
}
.c-list__value:last-child {
	margin-bottom: 0;
}

.c-horizontal-list:after {
	content: '';
	display: block;
	clear: both;
}
.c-horizontal-list__item {
	display: block;
	float: left;
	margin-right: 14px;
	margin-bottom: 3px;

	&:last-child {
		margin-right: 0;
	}
}

.c-horizontal-list--pipes .c-horizontal-list__item {
	border-right: 1px solid $highlight--darker;
	padding-right: 10px;
	margin-right: 10px;

	&:last-child,
	&--is-last {
		border-right: 0;
		margin-right: 0;
		padding-right: 0;
	}
}

.c-list--icons li {
	position: relative;
	margin-bottom: 10px;
	padding-left: 25px;
}
.c-list--icons li:before {
	position: absolute;
	left: 0;
	top: 3px;
}