.c-table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	text-align: left; 
	font-size: 0.9em;
}
.c-table th,
.c-table td {
	padding: 8px 0 8px 10px;
	vertical-align: top;
	float: none;
}
.c-table th { 
	padding-top: 8px;
	padding-bottom: 8px;
	background:#fff;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
	text-align: left
}
.c-table th:last-child,
.c-table td:last-child {
	padding-right: 10px; 
}
.c-table tr:nth-child(even) {
	background: #f9f9f9;
}
.c-table tr:nth-child(odd) {
	background: #fff;
}

/* Modifiers */
.c-table--align-center th,
.c-table--align-center td {
	vertical-align: middle;
}

.c-table--layout-fixed {
	table-layout: fixed;
}

/*
Responsive c-table styling for responsive c-table plugin.
Creates scrolling columns on screens less then the 'lap' breakpoint
*/
@media only screen and (max-width: #{$bp-lap}) {
	
	.c-table-wrapper .c-table {
		table-layout: auto;
	}
	.c-table-wrapper td,
	.c-table-wrapper th {
		position: relative;
		white-space: nowrap;
		overflow: hidden;
	}
	.c-table-wrapper {
		position: relative;
		overflow: hidden;
		border: 1px solid #ddd;
	}
	.c-table-wrapper__pinned {
		position: absolute;
		left: 0;
		top: 0;
		background: #fff;
		width: 35%;
		overflow: hidden;
		overflow-x: scroll;
		border-right: 1px solid #ccc;
	}
	.c-table-wrapper__pinned .c-table {
		width: 100%;
	}
	.c-table-wrapper__pinned th,
	.c-table-wrapper__pinned td {
		white-space: nowrap;
	}

	.c-table-wrapper__scroller {
		margin-left: 35%;
	}
	.c-table-wrapper__scroller {
		overflow: scroll;
		overflow-y: hidden;
	}	

	.c-table-wrapper__scroller th:first-child,
	.c-table-wrapper__scroller td:first-child {
		display: none;
	}
	
}

/* Table changes to "card" layout at the portable size */
@include mq($until: lap) {
	.c-table--card-layout {
        border: 0;
    }

    .c-table--card-layout thead {
        display: none;
    }

    .c-table--card-layout tr {
        margin-bottom: $defaultVerticalMargin/2;
        display: block;
        border: 1px solid $highlight--darker;
        padding: 0 10px;
        border-radius: 3px;
    }

    .c-table--card-layout tr:after {
        content: "";
        display: table;
        clear: both;
    }
  
    .c-table--card-layout tr:before {
        display: block;
        content: '';
    }

    .c-table--card-layout td {
        display: block;
        width: 100%;
        border-bottom: 1px dotted $highlight--darker;
        padding: 8px 0;

        &:last-child {
        	border-bottom: 0;
        	padding-right: 0;
        }
    }

    .c-table--card-layout td:before {
        content: attr(data-label);
        display: inline-block;
        min-width: 20%;
        font-weight: bold;
        margin-right: 15px;
        vertical-align: top;
    }

    .c-table--card-layout .c-table__td-content {
		display: inline-block;
    }
}