.s-form--highlight {
	background: $highlight--lighter;
	padding: 15px;
}
.s-form__fieldset {
	border: 0;
	padding: 0;
}
.s-form__legend {
	margin-bottom: 0.8em;
}
.s-form__actions {
	text-align: right;
	margin-top: 30px;
}
.s-form__action {
	display: inline-block;
 	margin-left: 15px;
}
.s-form__action:first-child {
	margin-left: 0;
}