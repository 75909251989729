.c-tabs__items {
	border-bottom: 1px solid $highlight--darker;
	@include clearfix();
}

.c-tabs__tab {
	display: block;
	float: left;
	margin-right: 5px;
	position: relative;
	top: 1px;

	&--is-first {
		margin-left: 10px;
	}
}

.c-tabs__link {
	display: block;
	padding: 10px 15px;
	border: 1px solid $highlight--darker;
	background: $highlight;
	color: lighten($bodyColour,15);
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	&:hover,
	&:focus {
		color: lighten($bodyColour,15);
		text-decoration: none;
	}

	&--is-active {
		background: #fff;
		border-bottom-color: #fff;
		color: $bodyColour;

		&:hover,
		&:focus {
			color: $bodyColour;
		}
	}
}

.c-tabs__content {
	padding: 5px 10px;
}

.js .c-tabs__content {
	display: none;
}
.js .c-tabs__content--is-active {
	display: block;
}

/* Tabs with borders */

$darkerBorderColour: #aaa;

.c-tabs--with-borders .c-tabs__items,
.c-tabs--with-borders .c-tabs__link--is-active {
	border-color: $darkerBorderColour;
}
.c-tabs--with-borders .c-tabs__link {
	border-bottom-color: $darkerBorderColour;
}
.c-tabs--with-borders .c-tabs__link--is-active {
	border-bottom-color: #fff;
}
.c-tabs--with-borders .c-tabs__tab--is-first {
	margin-left: 0;
}

.c-tabs--with-borders .c-tabs__items {
	margin-bottom: 0;
}
.c-tabs--with-borders .c-tabs__content {
	border: 1px solid $darkerBorderColour;
	border-top: 0;
	padding: 25px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}