.h-modal-animation .mfp-content {
    //transition: opacity .3s ease;
    opacity: 0;
}

.h-modal-animation.mfp-bg {
    transition: opacity .3s ease;
    animation-name: none;
    opacity: 0
}

.h-modal-animation.mfp-ready .mfp-content {
    opacity: 1
}

.h-modal-animation.mfp-ready.mfp-bg {
    opacity: .8;
}

.h-modal-animation.mfp-ready.mfp-bg.mfp-removing {
    opacity: 0;
}

.h-modal-animation .mfp-content {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
    //transition: all 0.15s ease-out;
}


/* content animate out */
.h-modal-animation.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}
.h-modal-animation.mfp-wrap.mfp-ready .mfp-content {
    animation-name: slideInDown;
}


@keyframes slideInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOutTop {
    0% {
        opacity: 0;
        transform: translateY(0)
    }

    100% {
        transform: translateY(-2000px)
    }
}
