/* -----------------------------------------------------------------------------

  HAMBURGER ICONS COMPONENT
  Highly modified from https://github.com/callmenick/Animating-Hamburger-Icons
----------------------------------------------------------------------------- */

// vars

$button-width: 16px;                    // The width of the button area
$button-height: 12px;                   // The height of the button area
$bar-thickness: 2px;                    // The thickness of the button bars
$button-pad: 0px;                      // The left/right padding between button area and bars.
$button-bar-space: 4px;                // The spacing between button bars
$button-transition-duration: 0.1s;     // The transition duration

/**
 * Toggle Switch Globals
 *
 * All switches should take on the class `c-hamburger` as well as their
 * variant that will give them unique properties. This class is an overview
 * class that acts as a reset for all versions of the icon.
 */

.i-hamburger {
  display: inline-block;
  position: relative;
  margin: 0 7px 0 0;
  padding: 0;
  width: $button-width;
  height: $button-height;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background $button-transition-duration;
}

.i-hamburger:focus {
  outline: none;
}

.i-hamburger span {
  display: block;
  position: absolute;
  top: ($button-height / 2) - ($bar-thickness / 2);
  left: $button-pad;
  right: $button-pad;
  height: $bar-thickness;
  background: #999;
  transition: background 0s $button-transition-duration;
}

.i-hamburger span::before,
.i-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: $bar-thickness;
  background-color: #999;
  content: "";
  transition-duration: $button-transition-duration, $button-transition-duration;
  transition-delay: $button-transition-duration, 0s;
}

.i-hamburger span::before {
  top: -$bar-thickness - $button-bar-space;
  transition-property: top, transform;
}

.i-hamburger span::after {
  bottom: -$bar-thickness - $button-bar-space;
  transition-property: bottom, transform;
}


/* active state, i.e. menu open */

.i-hamburger--is-active {
  background-color: none;
}

.i-hamburger--is-active span {
  background: none;
}

.i-hamburger--is-active span::before {
  top: 0;
  transform: rotate(45deg);
}

.i-hamburger--is-active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.i-hamburger--is-active span::before,
.i-hamburger--is-active span::after {
  transition-delay: 0s, $button-transition-duration;
}

/* Hamburger icon for old nav */

$button-width: 23px;                    // The width of the button area
$button-height: 27px;                   // The height of the button area
$bar-thickness: 2px;                    // The thickness of the button bars
$button-bar-space: 6px;                // The spacing between button bars

.i-hamburger--old-nav {
  width: $button-width;
  height: $button-height;
}
.i-hamburger--old-nav span {
  top: ($button-height / 2) - ($bar-thickness / 2) + 2px;
  height: $bar-thickness;
  background: #fff;
}

.i-hamburger--old-nav span::before,
.i-hamburger--old-nav span::after {
  height: $bar-thickness;
  background-color: #fff;
}

.i-hamburger span::before {
  top: -$bar-thickness - $button-bar-space;
}

.i-hamburger span::after {
  bottom: -$bar-thickness - $button-bar-space;
}

.c-nav-toggles__item--is-active .i-hamburger--old-nav {
  background-color: none;
}

.c-nav-toggles__item--is-active .i-hamburger--old-nav span {
  background: none;
}

.c-nav-toggles__item--is-active .i-hamburger--old-nav span::before {
  top: 0;
  transform: rotate(45deg);
}

.c-nav-toggles__item--is-active .i-hamburger--old-nav span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.c-nav-toggles__item--is-active .i-hamburger--old-nav span::before,
.c-nav-toggles__item--is-active .i-hamburger--old-nav span::after {
  transition-delay: 0s, $button-transition-duration;
}


/* User nav - When active, change to the cross */

.c-nav-toggles__item .i-user-nav {
  position: relative;
  display: inline-block;
  width: $button-width;
  height: $button-height;
}

.c-nav-toggles__item--is-active .i-user-nav:after {
  display: none;
}

.c-nav-toggles__item--is-active .i-user-nav span:before,
.c-nav-toggles__item--is-active .i-user-nav span:after {
    content: '';
    display: block;
    width: 100%;
    height: $bar-thickness;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 10px;
}

.c-nav-toggles__item--is-active .i-user-nav span:before {
    transform: rotate(45deg);
}

.c-nav-toggles__item--is-active .i-user-nav span:after {
    transform: rotate(-45deg);
}